import panda from './redpanda.webp';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <img src={panda} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
